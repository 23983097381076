import {get, post} from './http.js'
// 官网提交联系人
export const contacInfo = p => post('/API/contacInfo/add', p);
// 获取产品详情
export const getDetail = p => get('/API/product/getDetail', p)
// 获取产品列表
export const getList = p => get('/API/product/getList', p)
// 获取新闻列表
export const getNewsList = p => get('/API/news/getList', p)
// 获取公司荣誉列表
export const getHonorList = p => get('/API/honor/getList', p)
// 获取新闻详情
export const getNewsDetail = p => get('/API/news/getDetail', p)