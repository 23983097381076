<template>
  <div class="footer">
    <div class="top col alc colfff">
      <p class="top-tit">数字软件技术、数字媒体传播解决方案提供商</p>
      <form action="" class="form">
        <div class="input-list row alc">
          <label for="name">
            <span class="colfff">称呼</span>
            <input
              type="text"
              class="input"
              id="name"
              autocomplete="off"
              v-model="ciName"
            />
          </label>
        </div>
        <div class="input-list row alc">
          <label for="phone">
            <span class="colfff">联系电话</span>
            <input
              type="text"
              class="input"
              id="phone"
              autocomplete="off"
              v-model="ciPhone"
            />
          </label>
        </div>
        <div class="input-list row alc">
          <label for="nickname">
            <span class="colfff">企业名称</span>
            <input
              type="text"
              class="input"
              id="nickname"
              autocomplete="off"
              v-model="ciCompanyName"
            />
          </label>
        </div>
      </form>
      <div class="button" @click="contac">联系我们</div>
      <img class="top-bg" src="@/assets/footer_bg.png" />
    </div>
    <div class="bottom">
      <div class="row jusb">
        <img src="@/assets/logo.png" alt="" class="logo" />
        <div class="bot-list">
          <div class="list-tit">产品</div>
          <ul class="list-ul">
            <li @click="gotoPath('/screen')">数字一体化</li>
            <li @click="gotoPath('/enterprise')">数字墅企通</li>
            <li @click="gotoPath('/ad')">数字广告</li>
          </ul>
        </div>
        <div class="bot-list">
          <div class="list-tit">解决方案</div>
          <ul class="list-ul">
            <li @click="gotoPath('/data')">数字一体化解决方案</li>
            <li @click="gotoPath('/shuqitong')">数字墅企通解决方案</li>
            <li @click="gotoPath('/number')">数字广告解决方案</li>
          </ul>
        </div>
        <div class="bot-list">
          <div class="list-tit">了解我们</div>
          <ul class="list-ul">
            <li @click="gotoPath('/introduce')">公司介绍</li>
            <li @click="gotoPath('/introduce')">公司文化</li>
            <li @click="gotoPath('/index?id=panter')">合作伙伴</li>
            <li @click="gotoPath('/introduce')">荣誉证书</li>
            <li @click="gotoPath('/index?id=hot')">新闻动态</li>
          </ul>
        </div>
        <div class="bot-list">
          <div class="list-tit">联系我们</div>
          <ul class="ul-call">
            <li>联系电话：4004-111-666</li>
            <li>公司咨询：dandao@DD.com</li>
          </ul>
        </div>
      </div>
      <p class="copy">
        Copyright©2015-2021 丹道，All Rights Reserved. 浙江丹道网络科技有限公司
      </p>
    </div>
  </div>
</template>

<script>
import {contacInfo} from "@/http/api";
export default {
  data() {
    return {
      ciCompanyName: "",
      ciPhone: "",
      ciName: "",
    };
  },
  props: {},
  components: {},
  methods: {
    gotoPath(path) {
      this.$router.push(path);
    },
    contac() {
      if (this.ciName == "") {
        this.$message("请输入您的称呼");
        return;
      }

      if (this.ciPhone == "") {
        this.$message("请输入您的联系电话");
        return;
      }

      if (this.ciCompanyName == "") {
        this.$message("请输入您的企业名称");
        return;
      }
      let parmas = {
        ciCompanyName: this.ciCompanyName,
        ciPhone: this.ciPhone,
        ciName: this.ciName
      }
      contacInfo(parmas).then((res) => {
        if(res.code == 20000){
          this.ciCompanyName = ""
          this.ciPhone = ""
          this.ciName = ""
          this.$message.success(res.message)
        }else {
          this.$message.error(res.message)
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.footer {
  //   position: fixed;
  //   bottom: 0;
  width: 100%;
  font-size: 18px;
  .top {
    position: relative;
    height: 480px;
    background: #0068ff;
    .top-tit {
      padding-top: 34px;
      font-size: 36px;
      line-height: 67px;
      z-index: 1;
      margin-bottom: 28px;
    }
    .input-list {
      width: 600px;
      border-bottom: 1px solid #ffffff;
      padding: 20px 0 10px;
    }
    .form {
      z-index: 1;
      font-size: 24px;
      .input {
        line-height: 40px;
        background: transparent;
        border: none;
        outline: 0;
        color: #fff !important;
        font-size: 24px;
        padding: 0 20px;
        margin-left: 20px;
      }

      input:-internal-autofill-previewed {
        -webkit-text-fill-color: #ffffff !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }
      input:-internal-autofill-selected {
        -webkit-text-fill-color: #ffffff !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }
    }
    .top-bg {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      width: 100%;
      height: 250px;
      z-index: 0;
    }
    .button {
      width: 203px;
      height: 50px;
      background: #2a4bd7;
      border-radius: 8px;
      text-align: center;
      line-height: 50px;
      margin-top: 48px;
      cursor: pointer;
    }
  }
  .bottom {
    width: 100%;
    background: #0d1836;
    height: 380px;
    padding: 38px 182px 0 72px;
    box-sizing: border-box;
    position: relative;
    .logo {
      width: 150px;
      height: 50px;
    }
    .list-tit {
      font-size: 18px;
      color: #fff;
    }
    .list-ul {
      li {
        font-size: 14px;
        color: #929292;
        margin-top: 10px;
        cursor: pointer;
      }
    }
    .ul-call {
      li {
        font-size: 14px;
        color: #fff;
        margin-top: 15px;
      }
    }
    .copy {
      font-size: 14px;
      color: #fff;
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
</style>
