<template>
  <div
    id="header"
    class="header"
    :style="{ background: index ? '' : '#4ec7ff' }"
  >
    <img src="@/assets/logo.png" alt="" class="logo" />
    <div class="hd-items">
      <div :class="['item', path == 1 ? 'index-active' : 'transparent']" @click="gotoPath('/index')">首页</div>
      <div :class="['item', (path == 21 || path == 22 || path == 23) ? 'active' : 'transparent']">
        <div  @click="gotoPath('/ad')">产品介绍</div>
        <ul v-if="path == 122" class="item-ul">
          <li>数字一体化</li>
          <li>数字墅企通</li>
          <li>数字广告</li>
        </ul>
      </div>
      <div :class="['item', (path == 31 || path == 32 || path == 33) ? 'active' : 'transparent']">
        <div  @click="gotoPath('/data')">解决方案</div>
        <ul v-if="path == 122" class="item-ul">
          <li>数字一体化解决方案</li>
          <li>数字墅企通解决方案</li>
          <li>数字广告解决方案</li>
        </ul>
      </div>
      <div :class="['item', path == 6 ? 'active' : 'transparent']"   @click="gotoPath('/case')">经典案例</div>
      <!-- <div class="item">关于丹道</div> -->
      <div :class="['item', path == 5 ? 'active' : 'transparent']">
        <div  @click="gotoPath('/introduce')">关于丹道</div>
        <ul v-if="path == 122" class="item-ul">
          <li>公司介绍</li>
          <li>公司架构</li>
          <li>公司文化</li>
          <li>合作伙伴</li>
          <li>荣誉证书</li>
          <li>新闻动态</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Boolean,
      default: true,
    },
    path: {
      type: Number,
      default: 2,
    }
  },
  methods:{
    gotoPath(e){
      this.$router.push(e)
    }
  }
};
</script>
<style scoped lang="less">
.header {
  z-index: 99999;
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 256px 0 90px;
  box-sizing: border-box;
  // background: transparent;
  font-size: 18px;
  .logo {
    width: 150px;
    height: 50px;
  }
  .hd-items {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
    margin-left: 200px;
    .item {
      min-width: 150px;
      position: relative;
      text-align: center;
      line-height: 90px;
      cursor: pointer;
      height: 90px;
      box-sizing: border-box;
    }
    .item.active::after {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 55px;
      height: 5px;
      background: #0b4f6e;
      border-radius: 4px;
    }
    .item.index-active::after {
       position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      content: "";
      width: 55px;
      height: 5px;
      background: #4EC7FF;
      border-radius: 4px;
    }
    .item-ul {
      min-width: 70px;
      position: absolute;
      top: 80px;
      left: 50%;
      transform: translateX(-50%);
      background: #4E6EF2;
      font-size: 14px;
      padding: 6px 16px;
      // background: rgba(255, 255, 255, .6);
      color: #000;
      z-index: 66666;
    }
  }
}
</style>
