<template>
  <div class="index" id="index">
    <Header :path="1" :index="true"></Header>
    <div class="content">
      <div class="top">
        <div class="top-tit">
          <p class="tit-f">丹道科技，专注政企数字服务</p>
          <p class="tit-t">为政府和企业建立良好的数字化沟通平台</p>
          <a href="#call" class="btn">联系我们</a>
        </div>
      </div>
      <div class="center col alc">
        <p class="center-tit">我们解决的问题</p>
        <div class="list-box row jusb">
          <div
            :class="['list', 'col', 'alc', nowHover == 1 ? style : '']"
            @mouseenter="listChangeColor(1)"
            @mouseleave="listChange(1)"
          >
            <img
              v-if="nowHover == 1"
              src="@/assets/index/icon1_1.png"
              alt=""
              class="icon"
            />
            <img v-else src="@/assets/index/icon1_2.png" alt="" class="icon" />
            <p class="list-tit">企业诉求</p>
            <p class="list-text">企业诉求解决,</p>
            <p class="list-text">解决了方便了政企沟通</p>
          </div>
          <div
            :class="['list', 'col', 'alc', nowHover == 2 ? style : '']"
            @mouseenter="listChangeColor(2)"
            @mouseleave="listChange(2)"
          >
            <img
              v-if="nowHover == 2"
              src="@/assets/index/icon2_1.png"
              alt=""
              class="icon"
            />
            <img v-else src="@/assets/index/icon2_2.png" alt="" class="icon" />
            <p class="list-tit">营销决策</p>
            <p class="list-text">根据业务需求组合数据分析，以数</p>
            <p class="list-text">据支持决策，强化数据驱动广告投</p>
            <p class="list-text">放，提升投放ROI</p>
          </div>
          <div
            :class="['list', 'col', 'alc', nowHover == 3 ? style : '']"
            @mouseenter="listChangeColor(3)"
            @mouseleave="listChange(3)"
          >
            <img
              v-if="nowHover == 3"
              src="@/assets/index/icon3_1.png"
              alt=""
              class="icon"
            />
            <img v-else src="@/assets/index/icon3_2.png" alt="" class="icon" />
            <p class="list-tit">数字一体化</p>
            <p class="list-text">数字大屏数据一体化展示</p>
            <p class="list-text">快捷方便查看实时数据</p>
            <p class="list-text">反馈增强决策反馈</p>
          </div>
        </div>
        <div class="list-box row jusb">
          <div
            :class="['list', 'col', 'alc', nowHover == 4 ? style : '']"
            @mouseenter="listChangeColor(4)"
            @mouseleave="listChange(4)"
          >
            <img
              v-if="nowHover == 4"
              src="@/assets/index/icon4_1.png"
              alt=""
              class="icon"
            />
            <img v-else src="@/assets/index/icon4_2.png" alt="" class="icon" />
            <p class="list-tit">数字广告</p>
            <p class="list-text">帮助客户打造公域投放追踪道</p>
            <p class="list-text">私域精细化运营的闭环方案,</p>
            <p class="list-text">实现全域数字化营销</p>
          </div>
          <div
            :class="['list', 'col', 'alc', nowHover == 5 ? style : '']"
            @mouseenter="listChangeColor(5)"
            @mouseleave="listChange(5)"
          >
            <img
              v-if="nowHover == 5"
              src="@/assets/index/icon5_1.png"
              alt=""
              class="icon"
            />
            <img v-else src="@/assets/index/icon5_2.png" alt="" class="icon" />
            <p class="list-tit">数字黄页</p>
            <p class="list-text">实用黄页有效的锁定客户，追踪</p>
            <p class="list-text">客户，分析客户，预判成交意向</p>
            <p class="list-text">助力企业营销拓客</p>
          </div>
          <div
            :class="['list', 'col', 'alc', nowHover == 6 ? style : '']"
            @mouseenter="listChangeColor(6)"
            @mouseleave="listChange(6)"
          >
            <img
              v-if="nowHover == 6"
              src="@/assets/index/icon6_1.png"
              alt=""
              class="icon"
            />
            <img v-else src="@/assets/index/icon6_2.png" alt="" class="icon" />
            <p class="list-tit">在线客服</p>
            <p class="list-text">在线客服。实现企业不间断服务</p>
            <p class="list-text">提升客户满意度，节省客服80%</p>
            <p class="list-text">效率</p>
          </div>
        </div>
      </div>
      <div class="center-fn col alc">
        <p class="center-tit colfff">我们的解决方案</p>
        <div class="fn-list row jusb alc">
          <div class="list col alc">
            <img src="https://dandao-website.oss-cn-hangzhou.aliyuncs.com/official/shuzi_yiti.png" alt="" class="shuziimg" />
            <div class="text-box col jusc">
              <p class="list-tit">数字一体化解决方案</p>
              <p class="list-text">
                通过整合亿级优质用户流量，朋友圈广告为广告主提供了一个国内独一无二的互联网社交推广营销平台。
              </p>
            </div>
          </div>
          <div class="list col alc">
            <img src="https://dandao-website.oss-cn-hangzhou.aliyuncs.com/official/shuqi.png" alt="" class="shuqiimg" />
            <div class="text-box col jusc">
              <p class="list-tit">数企通解决方案</p>
              <p class="list-text">
                用数字技术创新职能履行方式，还包括政府应当为数字经济、数字社会的健康有序发展树立典范，提供必要支撑。
              </p>
            </div>
          </div>
          <div class="list col alc">
            <img src="https://dandao-website.oss-cn-hangzhou.aliyuncs.com/official/shuzi_ad.png" alt="" class="shuziimg" />
            <div class="text-box col jusc">
              <p class="list-tit">数字广告解决方案</p>
              <p class="list-text">
                通过整合亿级优质用户流量，朋友圈广告为广告主提供了一个国内独一无二的互联网社交推广营销平台。
              </p>
            </div>
          </div>
        </div>
        <img src="https://dandao-website.oss-cn-hangzhou.aliyuncs.com/official/shuzi_bg(2).png" alt="" class="shuzibg" />
      </div>
      <div class="center-fuwu col alc">
        <p class="fuwu-tit">强大的研发实力，安全稳定可靠的系统支持</p>
        <p class="fuwu-text">来自优秀技术研发团队，打造安全稳定的可靠的系统</p>
        <div class="fuwu-list">
          <p class="fuwu-p">安全稳定</p>
          <img src="@/assets/index/anquan.png" alt="" class="fuwu-icon" />
        </div>
        <div class="fuwu-list">
          <p class="fuwu-p">极致服务</p>
          <img src="@/assets/index/jizhi.png" alt="" class="fuwu-icon" />
        </div>
        <div class="fuwu-list">
          <p class="fuwu-p">智慧高效</p>
          <img src="@/assets/index/zhihui.png" alt="" class="fuwu-icon" />
        </div>
      </div>
      <div class="center-panter col alc" id="panter">
        <p class="center-tit">合作伙伴</p>
        <img src="https://dandao-website.oss-cn-hangzhou.aliyuncs.com/official/panter.png" alt="" class="panter-img" />
      </div>
      <div class="center-hot col alc" id="hot">
        <p class="center-tit">新闻动态</p>
        <div class="row jusb">
          <div class="hot-list" v-for="(item, index) in newsList" :key="index">
            <img :src="item.npictureUrl" alt="" class="hot-img" />
            <el-tooltip effect="dark" :content="item.ntitle" placement="top">
              <p class="hot-tit">{{ item.ntitle }}</p>
            </el-tooltip>
            <div class="hot-text-div">
              <p class="hot-text">
              {{ item.ncreateTime }}&nbsp;&nbsp;&nbsp;&nbsp;{{ item.ncontent }}
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a name="call"></a>
    <Fotter></Fotter>
  </div>
</template>

<script>
import { getNewsList } from "@/http/api";
import Header from "../components/header.vue";
import Fotter from "../components/fotter.vue";
export default {
  name: "index",
  components: {
    Header,
    Fotter,
  },
  data() {
    return {
      style: "normal",
      nowHover: 0,
      newsList: [],
    };
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$route);
      if (this.$route.query.id) {
        let id = this.$route.query.id;
        let el = document.getElementById(id);
        console.log(el);
        el.scrollIntoView({
          behavior: "smooth", // 平滑过渡
          block: "start",
          inline: "start",
        });
      } else {
        this.$router.afterEach((to, from, next) => {
          window.scrollTo(0, 0);
        });
      }
    });
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      let parmas = {
        pageSize: 3,
        pageNum: 1,
      };
      getNewsList(parmas).then((res) => {
        if (res.code == 20000) {
          for (var i in res.data.dataList) {
            res.data.dataList[i].ncontent = this.getSimpleText(
              res.data.dataList[i].ncontent
            );
          }
          this.newsList = res.data.dataList;
          console.log(this.newsList);
        }
      });
    },
    getSimpleText(html) {
      var re1 = new RegExp("<.+?>", "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
      var msg = html.replace(re1, ""); //执行替换成空字符
      return msg;
    },
    listChangeColor(e) {
      this.nowHover = e;
      this.style = "hover";
    },
    listChange(e) {
      this.nowHover = 0;
      this.style = "normal";
    },
  },
};
</script>
<style scoped lang="less">
.content {
  margin-top: -90px;
  .top {
    width: 100%;
    height: 824px;
    background: url("https://dandao-website.oss-cn-hangzhou.aliyuncs.com/official/index_bg.png") no-repeat;
    background-size: contain;
    font-size: 0;
    .top-tit {
      width: 100%;
      height: 100%;
      padding-left: 180px;
      box-sizing: border-box;
      z-index: 666;
      color: #fff;
      .tit-f {
        padding-top: 334px;
        font-size: 48px;
      }
      .tit-t {
        font-size: 28px;
        padding-top: 15px;
      }
      .btn {
        display: block;
        width: 160px;
        height: 50px;
        border: 2px solid #ffffff;
        border-radius: 8px;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        margin-top: 50px;
        cursor: pointer;
        color: #fff;
      }
    }
  }
  .center {
    width: 100%;
    height: 700px;
    background: url("https://dandao-website.oss-cn-hangzhou.aliyuncs.com/official/center_bg.png") no-repeat;
    background-size: contain;
    font-size: 0;
    .list-box {
      padding: 0 300px;
      box-sizing: border-box;
    }
    .list {
      width: 330px;
      height: 220px;
      margin-top: 30px;
      border-radius: 8px;
      cursor: default;
      .icon {
        width: 40px;
        height: 40px;
        margin-top: 20px;
      }
      .list-tit {
        font-size: 28px;
        padding: 20px 0;
      }
      .list-text {
        font-size: 18px;
        line-height: 25px;
      }
    }
    .normal {
      background: transprent;
      .list-tit {
        color: #333232;
      }
      .list-text {
        color: #000;
      }
    }

    .hover {
      background: #4ec7ff;
      .list-tit {
        color: #fff;
      }
      .list-text {
        color: #fff;
      }
    }
  }

  .center-tit {
    margin-top: 30px;
    font-size: 36px;
    color: #333232;
    line-height: 50px;
    position: relative;
    margin-bottom: 45px;
    z-index: 10;
    &::after {
      content: "";
      position: absolute;
      bottom: -16px;
      left: 50%;
      transform: translateX(-50%);
      width: 150px;
      height: 10px;
      background: linear-gradient(90deg, #a3eef3, #678df4 50%, #a3eef3);
      border-radius: 5px;
    }
  }
  .center-fn {
    width: 100%;
    height: 1043px;
    background: #00223b;
    position: relative;
    .center-tit {
      margin-bottom: 160px !important;
    }
    .fn-list {
      padding: 0 350px;
      box-sizing: border-box;
    }
    .shuzibg {
      width: 1253px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      flex: none;
    }
    .list {
      z-index: 10;
      .text-box {
        width: 303px;
        border: 2px solid #ffffff;
        border-radius: 8px;
        padding: 0 15px 8px;
        box-sizing: border-box;
        color: #fff;
        margin-top: 15px;
        .list-tit {
          text-align: center;
          font-size: 24px;
        }
        .list-text {
          font-size: 18px;
          line-height: 25px;
        }
      }
    }
    .shuziimg {
      width: 303px;
      height: 338px;
    }
    .shuqiimg {
      width: 425px;
      height: 431px;
    }
  }
  .center-fuwu {
    width: 100%;
    height: 1457px;
    background: url("https://dandao-website.oss-cn-hangzhou.aliyuncs.com/official/anquan_bg.png") no-repeat;
    background-size: cover;
    padding: 60px 0 0 0;
    box-sizing: border-box;
    .fuwu-tit {
      font-size: 36px;
    }
    .fuwu-text {
      color: #6b6b6b;
      font-size: 24px;
      margin: 30px 0 78px;
    }
    .fuwu-list {
      width: 1633px;
      height: 256px;
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.39);
      padding: 28px 90px;
      box-sizing: border-box;
      position: relative;
      margin-bottom: 50px;
      .fuwu-p {
        font-size: 24px;
      }
      .fuwu-icon {
        width: 100px;
        height: 100px;
        position: absolute;
        top: 50%;
        right: 88px;
        transform: translateY(-50%);
      }
    }
  }
  .center-panter {
    padding: 0 0 54px;
  }
  .panter-img {
    width: 1440px;
    height: 434px;
  }
  .center-hot {
    background: #ececec;
    padding: 0 120px 90px;
    box-sizing: border-box;
    .hot-img {
      width: 496px;
      height: 240px;
    }
    .hot-tit {
      font-size: 24px;
      padding: 10px 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 496px;
    }
    .hot-text-div {
      max-height: 80px;
    }

    .hot-text {
      color: #5e5e5e;
      font-size: 18px;
      width: 496px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
}
</style>
