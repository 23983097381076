import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/index',
    name: 'index',
    component: index
  },
  {
    path: '/screen',
    name: 'screen',
    component: () => import('../views/product/screen.vue')
  },
  {
    path: '/enterprise',
    name: 'enterprise',
    component: () => import('../views/product/enterprise.vue')
  },
  {
    path: '/ad',
    name: 'ad',
    component: () => import('../views/product/ad.vue')
  },
  {
    path: '/introduce',
    name: 'introduce',
    component: () => import('../views/introduce.vue')
  },
  {
    path: '/number',
    name: 'number',
    component: () => import('../views/solution/number.vue')
  },
  {
    path: '/data',
    name: 'data',
    component: () => import('../views/solution/data.vue')
  },
  {
    path: '/shuqitong',
    name: 'shuqitong',
    component: () => import('../views/solution/shuqitong.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/case.vue')
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})
export default router
